<template>
  <div>
    <el-container>
      <!-- el-aside -->
      <el-aside width="10%">
        <el-select
          v-model="selectEmployeeState"
          placeholder="请选择"
          @change="onSelectEmployeeState"
        >
          <el-option
            v-for="item in employeeStateData"
            :key="item.Value"
            :label="'员工状态 - ' + item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
        <div>
          <div
            class="position"
            v-for="(item, index) in dutyList"
            :key="item.DutyNo"
            :class="indexAction == index ? 'action' : ''"
            @click="onSelectEmplType(index, item)"
          >
            {{ item.DutyName }}
          </div>
        </div>
      </el-aside>
      <!-- end el-aside -->
      <!-- el-main -->
      <el-main>
        <!-- top -->
        <div class="top">
          <div class="right">
            <el-input
              placeholder="请输入内容"
              v-model="searchText"
              size="mini"
            ></el-input>
            <el-button type="primary" size="mini" @click="onSearch"
              >查询</el-button
            >
            <el-button
              @click="onAddEmployee"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              >新增员工</el-button
            >
          </div>
        </div>
        <!-- end top -->
        <!-- table -->
        <el-table
          :data="emplList"
          style="width: 100%"
          height="calc(100vh - 150px)"
          v-loading="loading"
        >
          <el-table-column type="selection" width="48"></el-table-column>
          <el-table-column prop="date" label="员工信息" width="250">
            <template slot-scope="scope">
              <div class="image-text">
                <el-image :src="scope.row.Picture">
                  <div class="el-image" slot="error">
                    {{ scope.row.EmplName | cutoutString }}
                  </div>
                </el-image>
                <div class="text">
                  <div>
                    <span>{{ scope.row.EmplName }}</span>
                    <i
                      :class="
                        scope.row.EmplSex ? 'el-icon-male' : 'el-icon-female'
                      "
                    ></i>
                  </div>
                  <div class="size">工号:{{ scope.row.EmplNo }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="EmplSex" label="性别" width="80">
            <template slot-scope="scope">{{
              scope.row.EmplSex | whether
            }}</template>
          </el-table-column>
          <el-table-column
            prop="DeptName"
            label="部门"
            width="120"
          ></el-table-column>
          <el-table-column prop="DutyName" label="职位" width="120">
          </el-table-column>
          <el-table-column prop="LevelName" label="级别" width="140">
            <template slot-scope="scope">
              <span>{{ scope.row.LevelName }}</span>
              <div v-show="scope.row.ViceItems != ''" class="vice">
                [
                <span
                  v-for="(item, index) in scope.row.ViceItems"
                  :key="index"
                  >{{ (index == 0 ? "" : ", ") + item.LevelName }}</span
                >
                ]
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="手机号码" width="120">
            <template slot-scope="scope">
              <div class="phone-box">
                <span>{{ scope.row.EmplPhone }}</span>
                <span class="vice">{{ scope.row.PhoneSeach ? "已开通手机查询" : "" }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="CardNo" label="考勤卡号" width="120">
          </el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.EmplState == 1"
                >在职</el-tag
              >
              <el-tag type="info" v-if="scope.row.EmplState == 2">离职</el-tag>
              <el-tag type="danger" v-if="scope.row.EmplState == 3"
                >删除</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="EmplNote"
            label="备注"
            show-overflow-tooltip
          ></el-table-column>
          <!-- <el-table-column prop="" label="员工介绍" show-overflow-tooltip></el-table-column> -->
          <el-table-column fixed="right" label="操作" width="160">
            <template slot-scope="scope">
              <div>
                <el-button
                  :disabled="scope.row.EmplState !== 1"
                  type="primary"
                  size="mini"
                  @click="onAlterEmployee(scope.row)"
                  >修改</el-button
                >

                <el-popover
                  popper-class="popper"
                  placement="bottom"
                  style="min-width: 0"
                  trigger="hover"
                >
                  <div class="popover-button-box">
                    <el-button
                      :disabled="scope.row.EmplState !== 1"
                      type="danger"
                      size="mini"
                      @click="onDeleteEmployee(scope.row)"
                      >删除</el-button
                    >
                    <el-button
                      :disabled="scope.row.EmplState !== 1"
                      type="info"
                      size="mini"
                      @click="onDeputyEmployee(scope.row)"
                      >副职</el-button
                    >
                    <el-button
                      :disabled="scope.row.EmplState !== 1"
                      type="danger"
                      plain
                      size="mini"
                      @click="onShowDismiss(scope.row)"
                      >离职</el-button
                    >
                  </div>
                  <el-button
                    style="margin-left: 10px;"
                    class="more"
                    size="mini"
                    slot="reference"
                    >更多</el-button
                  >
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- end table -->
        <EditDialog
          ref="rditDialog"
          :etitle="etitle"
          :formData="formData"
          :deptList="deptList"
          :dutyList="dutyListDialog"
          :levelList="levelList"
          :power="power"
          :roundType="roundType"
          :introduceSelector="introduceSelector"
          :limitModeList="limitModeList"
          @success="initPageData"
        >
        </EditDialog>

        <DeputyDialog ref="deputyDialog" @success="initPageData">
        </DeputyDialog>

        <DismissEmplDialog
          ref="DismissEmplDialog"
          @success="initPageData"
        ></DismissEmplDialog>
      </el-main>
      <!-- end el-main -->
    </el-container>
  </div>
</template>

<script>
import employee from "@/api/employee.js";
import EditDialog from "./components/edit-dialog.vue";
import DeputyDialog from "./components/deputy-dialog.vue";
import DismissEmplDialog from "./components/dismiss-empl-dialog.vue";

export default {
  components: { EditDialog, DeputyDialog, DismissEmplDialog },
  data() {
    return {
      searchText: "",
      employeeStateData: [],
      selectEmployeeState: 1,
      dutyList: [],
      indexAction: 0,
      emplList: [],
      selectDuty: "",
      loading: true,
      showDialog: false,
      deptList: [],
      dutyListDialog: [],
      limitModeList: [],
      levelList: [],
      power: [],
      roundType: [],
      introduceSelector: [],
      formData: {},
      etitle: "",
    };
  },
  mounted() {
    this.initPageData();
  },

  filters: {
    whether(param) {
      return param == 0 ? "女" : "男";
    },

    cutoutString(param) {
      return param.substring(0, 1);
    },
  },

  methods: {
    async initPageData() {
      this.loading = true;
      try {
        let submitData = {
          word: this.searchText,
          empl_state: this.selectEmployeeState,
          duty_no: this.selectDuty,
        };
        let { data } = await employee.getEmplMainList(submitData);
        let { dutyList, emplList, emplState } = data;
        this.employeeStateData = emplState;
        this.dutyList = [
          {
            DutyName: "全部",
            DutyNo: "",
            Sort: 0,
            StoresGuid: "",
            UseExpe: 0,
            ViceSymbol: "",
          },
          ...dutyList,
        ];
        this.emplList = emplList;
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    // 搜索员工
    onSearch() {
      this.initPageData();
    },

    // 选择员工类型
    onSelectEmplType(index, item) {
      this.indexAction = index;
      this.selectDuty = item.DutyNo;
      this.initPageData();
    },

    // 选择员工状态
    onSelectEmployeeState(event) {
      this.initPageData();
    },

    // 删除员工
    onDeleteEmployee(event) {
      this.$confirm(`是否删除该‘${event.EmplName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await employee.deleteEmpl({
              empl_guid: event.EmplGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {});
    },

    dataFormat() {
      return {
        CardNo: "",
        DeptGuid: "",
        DimissionDate: "",
        DutyNo: "",
        EmplName: "",
        EmplNo: "",
        EmplNote: "",
        EmplSex: 0,
        EntryDate: "",
        FreeRound: 0,
        PhoneSeach: 0,
        HideWxapp: 1,
        IDNumber: "",
        IntroduceGuid: "",
        LevelGuid: "",
        ListPowerNo: [],
        Picture: "",
        PictureUrl: "",
        RecruitWay: "",
        Sort: "",
        StoresGuid: "",
        Telephone: "",
        UpRoundType: "",
      };
    },

    // 初始化员工数据
    async initEmployeeData(empl_guid) {
      try {
        let submitData = empl_guid ? { empl_guid } : {};
        let { data } = await employee.initSaveEmplData(submitData);
        let {
          deptList,
          dutyList,
          levelList,
          power,
          roundType,
          initEmpl,
          introduceSelector,
          limitModeList,
        } = data;

        power.forEach((item) => {
          item.isSelect = false;
        });

        if (empl_guid) {
          // 员工权限
          let listPowerNo = initEmpl.ListPowerNo;
          for (var i = 0; i < listPowerNo.length; i++) {
            for (var j = 0; j < power.length; j++) {
              if (listPowerNo[i] == power[j].PowerNo) {
                power[j].isSelect = true;
              }
            }
          }

          // 日期格式
          // initEmpl.EntryDate = initEmpl.EntryDate == 0 ? '' : this.util.dateFormat('YYYY-mm-dd', new Date(initEmpl.EntryDate * 1000));
          // initEmpl.DimissionDate = initEmpl.DimissionDate == 0 ? '' : this.util.dateFormat('YYYY-mm-dd', new Date(initEmpl.DimissionDate * 1000));
        }

        if (!initEmpl) {
          initEmpl = this.dataFormat();
        }
        initEmpl.UpRoundType = Number(initEmpl.UpRoundType || "0");

        this.deptList = deptList;
        this.dutyListDialog = dutyList;
        this.levelList = levelList;
        this.power = power;
        this.roundType = roundType;
        this.introduceSelector = introduceSelector;
        this.formData = initEmpl;
        this.limitModeList = limitModeList;
        this.onShowEditDialog();
      } catch (e) {
        console.error(e);
      }
    },

    // 添加员工
    onAddEmployee() {
      this.etitle = "添加员工";
      this.initEmployeeData();
    },

    // 修改员工
    onAlterEmployee(event) {
      console.log(event);
      this.etitle = "修改员工";
      this.initEmployeeData(event.EmplGuid);
    },

    onShowDismiss(event) {
      this.$refs.DismissEmplDialog.onShowDialog(event.EmplGuid);
    },

    onDeputyEmployee(event) {
      // this.initEmployeeData(event.EmplGuid)
      this.$refs.deputyDialog.openDeputyDialog(event.EmplGuid);
    },

    onShowEditDialog() {
      this.$refs.rditDialog.onShowDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  .el-aside {
    height: calc(100vh - 100px);
    border-right: 1px solid #d3dce6;
    .el-select {
      //width: 219px;
      margin-bottom: 10px;
      box-sizing: border-box;
    }
    .position {
      height: 36px;
      line-height: 36px;
      padding-left: 15px;
      cursor: pointer;
    }
    .action {
      background-color: #f5f7fa;
    }
  }

  .el-main {
    position: relative;
    padding: 0 0 0 20px;

    .top {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;
      .right {
        display: flex;
        align-items: center;
      }
      .el-input {
        width: 300px !important;
      }
      .el-button {
        margin-left: 10px;
      }
    }

    .el-table {
      .image-text {
        display: flex;
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 56px;
          margin-left: 10px;
          // width: 200px;
          flex: 1;
          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .size {
            font-size: 10px;
          }
          i {
            font-weight: bold;
            margin-left: 5px;
          }
        }
      }
      .el-image {
        width: 56px;
        height: 56px;
        text-align: center;
        line-height: 56px;
        font-weight: bold;
        font-size: 20px;
        border-radius: 5px;
        vertical-align: middle;
        background-color: #c8c5c8;
      }

      .el-icon-male {
        color: #6aabdb;
      }

      .el-icon-female,
      .vice {
        color: tomato;
      }

      .phone-box {
        .flex-col;
        align-items: unset;
      }
    }

    .el-pagination {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.popover-button-box {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  margin: 0 !important;

  ::v-deep .el-button {
    // width: 100px;
    margin: 5px 5px;
  }
}
</style>
